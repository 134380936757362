<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.异常明细')"
                 fixed
                 right-:text="$t('tbm.确认')"
                 @click-left="back"
                 @click-right="submitForm">
    </van-nav-bar>
    <div style="padding-top:46px;">
      <van-form ref="forms">
        <van-field readonly
                   clickable
                   required
                   is-link
                   input-align="right"
                   name="calendar"
                   :value="form.dateDetail ? form.dateDetail.split(' ')[0] : form.dateDetail"
                   :label="$t('tbm.日期')"
                   :placeholder="$t('tbm.请选择日期')"
                   @click="showdateDetail = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择日期'),trigger: 'change'}]" />
        <van-calendar v-model="showdateDetail"
                      @confirm="dateDetailChange" />

        <van-field readonly
                   clickable
                   v-model="form.userName"
                   :label="$t('tbm.姓名')"
                   input-align="right"
                   placeholder="" />
        <van-field readonly
                   clickable
                   v-model="form.deptName"
                   :label="$t('tbm.所在部门')"
                   input-align="right"
                   placeholder="" />

        <van-field readonly
                   clickable
                   name="picker"
                   style="margin-top:10px;"
                   :value="pkWorkhourText"
                   :label="$t('tbm.工作时间')"
                   required
                   is-link
                   input-align="right"
                   :placeholder="$t('tbm.请选择工作时间')"
                   @click="showpkWorkhourRangePicker = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择工作时间'),trigger: 'change' }]" />
        <van-popup v-model="showpkWorkhourRangePicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="form.workTimes"
                      @confirm="onTemplateConfirm"
                      @cancel="showpkWorkhourRangePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   name="picker"
                   :value="signFlagText"
                   label="签到/签退"
                   required
                   is-link
                   input-align="right"
                   placeholder="请选择签到/签退"
                   @click="showsignFlagPicker = true"
                   :rules="[{ required: true, message: '请选择签到/签退',trigger: 'change' }]" />
        <van-popup v-model="showsignFlagPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="signFlagList"
                      @confirm="onsignFlagConfirm"
                      @cancel="showsignFlagPicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   name="picker"
                   :value="unusualTypeText"
                   :label="$t('tbm.异常申请类型')"
                   required
                   is-link
                   input-align="right"
                   :placeholder="$t('tbm.请选择异常申请类型')"
                   @click="showunusualTypePicker = true"
                   :rules="[{ required: true, message: this.$t('tbm.请选择异常申请类型'),trigger: 'change' }]" />
        <van-popup v-model="showunusualTypePicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="unusualTypeList"
                      @confirm="onunusualTypeConfirm"
                      @cancel="showunusualTypePicker = false" />
        </van-popup>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showdateDetail: false,
      showpkWorkhourRangePicker: false,
      showsignFlagPicker: false,
      showunusualTypePicker: false,
      form: {
        id: '',
        userName: '',
        deptName: '',
        dateDetail: '',
        workTimes: [],
        pkWorkhourRange: '',
        pkWorkhour: '',
        signFlag: '',
        unusualType: ''
      },
      pkWorkhourText: '',
      signFlagList: [
        {
          value: '0',
          text: '签到'
        }, {
          value: '1',
          text: '签退'
        }
      ],
      signFlagText: '',
      unusualTypeList: [
        { value: '05', text: '设备故障' },
        { value: '06', text: '交通管制' },
        { value: '07', text: '交通故障' },
        { value: '00', text: '忘记打卡' },
      ],
      unusualTypeText: ''
    }
  },
  methods: {
    back () {
      this.$router.replace({
        name: 'tbm/applyUnusual/applyUnusualEdit',
        query: {
          ...this.$route.query
        },
        params: {
          ...this.$route.params,
        }
      })
    },
    onTemplateConfirm (value) {
      this.form.pkWorkhour = value.pkWorkhour;
      this.form.pkWorkhourRange = value.value;
      this.pkWorkhourText = value.text;
      this.showpkWorkhourRangePicker = false;
    },
    onsignFlagConfirm (value) {
      this.form.signFlag = value.value;
      this.signFlagText = value.text;
      this.showsignFlagPicker = false;
    },
    onunusualTypeConfirm (value) {
      this.form.unusualType = value.value;
      this.unusualTypeText = value.text;
      this.showunusualTypePicker = false;
    },
    submitForm () {
      this.$refs.forms.validate().then(() => {
        if (this.form.dateDetail !== '') {
          const d = new Date(this.form.dateDetail)
          const resDate = d.getFullYear() + '-' + this.p((d.getMonth() + 1)) + '-' + this.p(d.getDate());
          this.form.dateDetail = resDate;
        }
        var obj = {};
        obj.rowIndex = this.$route.query.rowIndex || -1;
        obj.data = this.form;
        this.$router.replace({
          name: 'tbm/applyUnusual/applyUnusualEdit',
          query: {
            ...this.$route.query
          },
          params: {
            ...this.$route.params,
            obj
          }
        })
      })
    },
    p (s) {
      return s < 10 ? '0' + s : s;
    },
    dateDetailChange (date) {
      this.form.dateDetail = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      this.showdateDetail = false;
      this.getRangeNameList();
    },
    getRangeNameList () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyUnusual/getRangeNameList',
        params: {
          'dateDetail': this.form.dateDetail
        }
      }).then(res => {
        let backData = res.data.ext;
        if (backData.length > 0) {
          if (backData.length === 1) {
            this.form.pkWorkhourRange = backData[0].id
            this.form.pkWorkhour = backData[0].pkWorkhour
          }
          this.form.workTimes = [];
          backData.map(data => {
            let obj = {}
            obj['value'] = data.id
            obj['text'] = data.rangeName + '(' + data.startTime + '-' + data.endTime + ')'
            obj['pkWorkhour'] = data.pkWorkhour
            this.form.workTimes.push(obj);
            if (this.form.pkWorkhour == data.pkWorkhour) {
              this.pkWorkhourText = obj['text'];
            }
          })
        } else {
          this.form.workTimes = [];
        }
      });
    },
  },
  created () {
    let dataRow = this.$route.query.dataRow;
    console.log(dataRow)
    if (dataRow) {
      this.form = { ...dataRow };
      this.getRangeNameList();
      if (this.form.signFlag) {
        let index = this.signFlagList.findIndex(item => item.value == this.form.signFlag);
        if (index != -1) {
          this.signFlagText = this.signFlagList[index].text;
        }
      }
      if (this.form.unusualType) {
        let index = this.unusualTypeList.findIndex(item => item.value == this.form.unusualType);
        if (index != -1) {
          this.unusualTypeText = this.unusualTypeList[index].text;
        }
      }
    }
    this.form.deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
    this.form.userName = JSON.parse(localStorage.getItem('userInfo')).cname;
  }
}
</script>
